import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface, ValidatorFormGroupInterface } from '@common/ports/interfaces';

import { ClientAbstraction } from '@clients/ports/abstractions';
import { BulkLoadInterface, ClientInterface, ClientProfileInterface } from '@clients/ports/interfaces';
import { ValidatorFormGroupHelper } from '@common/domain/helpers';

@Injectable({
  providedIn: 'root'
})
export class ClientRepository extends ClientAbstraction {
  private readonly _baseUrlClientCreate: string = environment.baseUrlClientCreate;
  private readonly _baseUrlClientDelete: string = environment.baseUrlClientDelete;
  private readonly _baseUrlClientList: string = environment.baseUrlClientList;
  private readonly _baseUrlClientRead: string = environment.baseUrlClientRead;
  private readonly _baseUrlClientUpdate: string = environment.baseUrlClientUpdate;
  private readonly _baseUrlClientUpload: string = environment.baseUrlClientUpload;
  private readonly _baseUrlClientProfile: string = environment.baseUrlClientProfile;

  private validFormGroup: ValidatorFormGroupInterface = new ValidatorFormGroupHelper();

  private http = inject(HttpClient);

  override list(query: string): Observable<RespListInterface<ClientInterface>> {
    const url: string = this._baseUrlClientList;
    return this.http.get<RespListInterface<ClientInterface>>(`${url}/${query}`);
  }

  override create(Client: ClientInterface): Observable<RespInterface<string>> {
    const { id, institutions, typeIdentification, password, isActive, city, ...newClient } = Client;
    const url: string = this._baseUrlClientCreate;
    return this.http.post<RespInterface<string>>(`${url}`, newClient);
  }

  override update(Client: ClientInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlClientUpdate;
    const { id, institutions, typeIdentification, city, ...newClient } = Client;
    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newClient);
  }

  override read(id: string): Observable<RespInterface<ClientInterface>> {
    const url: string = this._baseUrlClientRead;
    return this.http.get<RespInterface<ClientInterface>>(`${url}/${id}`);
  }

  override delete(id: string): Observable<RespInterface<string>> {
    const url: string = this._baseUrlClientDelete;
    return this.http.delete<RespInterface<string>>(`${url}/${id}`);
  }

  override fileUpload(client: BulkLoadInterface): Observable<RespInterface<any>> {
    const url: string = this._baseUrlClientUpload;
    const formData = this.validFormGroup.formData(client);
    return this.http.post<any>(`${url}`, formData);
  }

  override profile(): Observable<RespInterface<ClientInterface>> {
    const url: string = this._baseUrlClientProfile;
    return this.http.get<RespInterface<ClientInterface>>(`${url}`);
  }

  override profileUpdate(client: ClientProfileInterface): Observable<RespInterface<ClientInterface>> {
    const url: string = this._baseUrlClientProfile;
    return this.http.patch<RespInterface<ClientInterface>>(`${url}`, client);
  }
}
