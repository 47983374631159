import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface } from '@common/ports/interfaces';

import { CountryAbstraction } from '@countries/ports/abstractions';
import { CountryInterface } from '@countries/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CountryRepository extends CountryAbstraction {

  private readonly _baseUrlCountryCreate: string = environment.baseUrlCountryCreate;
  private readonly _baseUrlCountryDelete: string = environment.baseUrlCountryDelete;
  private readonly _baseUrlCountryList: string = environment.baseUrlCountryList;
  private readonly _baseUrlCountryRead: string = environment.baseUrlCountryRead;
  private readonly _baseUrlCountryUpdate: string = environment.baseUrlCountryUpdate;

  private http = inject(HttpClient);

  override list(query: string): Observable<RespListInterface<CountryInterface>> {
    const url: string = this._baseUrlCountryList;
    return this.http.get<RespListInterface<CountryInterface>>(`${url}/${query}`);
  }

  override create(country: CountryInterface): Observable<RespInterface<CountryInterface>> {
    const { id: _id, ...newCountry } = country;
    const url: string = this._baseUrlCountryCreate;
    return this.http.post<RespInterface<CountryInterface>>(`${url}`, newCountry);
  }

  override update(country: CountryInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlCountryUpdate;
    const { id, ...newCountry } = country;
    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newCountry);
  }

  override read(id: string): Observable<RespInterface<CountryInterface>> {
    const url: string = this._baseUrlCountryRead;
    return this.http.get<RespInterface<CountryInterface>>(`${url}/${id}`);
  }

  override delete(id: string): Observable<RespInterface<CountryInterface>> {
    const url: string = this._baseUrlCountryDelete;
    return this.http.delete<RespInterface<CountryInterface>>(`${url}/${id}`);
  }
}
