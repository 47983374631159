import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const isAuthenticatedGuard: CanActivateFn = (route, state) => {

  const router = inject(Router);

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  
  const firstName = localStorage.getItem('firstName');
  const idUser = localStorage.getItem('idUser');
  const lastName = localStorage.getItem('lastName');
  const type = localStorage.getItem('type');
  const name = localStorage.getItem('name');



  if (type) {
    if (token && firstName && idUser && lastName && type) return true;
  } else {
    if (token && name && idUser) return true;
  }

  localStorage.clear();
  sessionStorage.clear();
  router.navigateByUrl('/');
  return false;
};
