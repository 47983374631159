import { Injectable } from '@angular/core';
import { LoadingAbstraction } from '@common/ports/abstractions';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingRepository extends LoadingAbstraction {

  override isLoading$ = new Subject<boolean>();

  override show(): void {
    this.isLoading$.next(true);
  }

  override hide(): void {
    this.isLoading$.next(false);
  }
}
