import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface } from '@common/ports/interfaces';

import { DeliveryTypeAbstraction } from '@delivery-type/ports/abstractions';
import { DeliveryTypeInterface } from '@delivery-type/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DeliveryTypeRepository extends DeliveryTypeAbstraction {

  private readonly _baseUrlDeliveryTypeCreate: string = environment.baseUrlDeliveryTypeCreate;
  private readonly _baseUrlDeliveryTypeDelete: string = environment.baseUrlDeliveryTypeDelete;
  private readonly _baseUrlDeliveryTypeList: string = environment.baseUrlDeliveryTypeList;
  private readonly _baseUrlDeliveryTypeRead: string = environment.baseUrlDeliveryTypeRead;
  private readonly _baseUrlDeliveryTypeUpdate: string = environment.baseUrlDeliveryTypeUpdate;

  private http = inject(HttpClient);

  override list(query: string): Observable<RespListInterface<DeliveryTypeInterface>> {
    const url: string = this._baseUrlDeliveryTypeList;
    return this.http.get<RespListInterface<DeliveryTypeInterface>>(`${url}/${query}`);
  }

  override create(deliveryType: DeliveryTypeInterface): Observable<RespInterface<DeliveryTypeInterface>> {
    const { id: _id, ...newDeliveryType } = deliveryType;
    const url: string = this._baseUrlDeliveryTypeCreate;
    return this.http.post<RespInterface<DeliveryTypeInterface>>(`${url}`, newDeliveryType);
  }

  override update(deliveryType: DeliveryTypeInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlDeliveryTypeUpdate;
    const { id, ...newDeliveryType } = deliveryType;
    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newDeliveryType);
  }

  override read(id: string): Observable<RespInterface<DeliveryTypeInterface>> {
    const url: string = this._baseUrlDeliveryTypeRead;
    return this.http.get<RespInterface<DeliveryTypeInterface>>(`${url}/${id}`);
  }

  override delete(id: string): Observable<RespInterface<DeliveryTypeInterface>> {
    const url: string = this._baseUrlDeliveryTypeDelete;
    return this.http.delete<RespInterface<DeliveryTypeInterface>>(`${url}/${id}`);
  }
}
