import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface } from '@common/ports/interfaces';

import { ProductServiceAbstraction } from '@products-services/ports/abstractions';
import { ProductServiceInterface } from '@products-services/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProductServiceRepository extends ProductServiceAbstraction {

  private readonly _baseUrlProductServiceCreate: string = environment.baseUrlProductServiceCreate;
  private readonly _baseUrlProductServiceDelete: string = environment.baseUrlProductServiceDelete;
  private readonly _baseUrlProductServiceList: string = environment.baseUrlProductServiceList;
  private readonly _baseUrlProductServiceRead: string = environment.baseUrlProductServiceRead;
  private readonly _baseUrlProductServiceUpdate: string = environment.baseUrlProductServiceUpdate;

  private http = inject(HttpClient);

  override list(query: string): Observable<RespListInterface<ProductServiceInterface>> {
    const url: string = this._baseUrlProductServiceList;
    return this.http.get<RespListInterface<ProductServiceInterface>>(`${url}/${query}`);
  }

  override create(productService: ProductServiceInterface): Observable<RespInterface<ProductServiceInterface>> {
    const { id, deliveryTypes, institutions, ...newProductService } = productService;
    const url: string = this._baseUrlProductServiceCreate;
    return this.http.post<RespInterface<ProductServiceInterface>>(`${url}`, newProductService);
  }

  override update(productService: ProductServiceInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlProductServiceUpdate;
    const { id, deliveryTypes, institutions, ...newProductService } = productService;
    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newProductService);
  }

  override read(id: string): Observable<RespInterface<ProductServiceInterface>> {
    const url: string = this._baseUrlProductServiceRead;
    return this.http.get<RespInterface<ProductServiceInterface>>(`${url}/${id}`);
  }

  override delete(id: string): Observable<RespInterface<ProductServiceInterface>> {
    const url: string = this._baseUrlProductServiceDelete;
    return this.http.delete<RespInterface<ProductServiceInterface>>(`${url}/${id}`);
  }
}
