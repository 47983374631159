import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { ActionInterface, ModulesInterface, RespInterface, RespListInterface } from '@common/ports/interfaces';

import { RolPermissionAbstraction } from '@roles-permissions/ports/abstractions';
import { RolPermissionInterface } from '@roles-permissions/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class RolPermissionRepository extends RolPermissionAbstraction {

  private readonly _baseUrlRolCreate: string = environment.baseUrlRolCreate;
  private readonly _baseUrlRolDelete: string = environment.baseUrlRolDelete;
  private readonly _baseUrlRolList: string = environment.baseUrlRolList;
  private readonly _baseUrlRolModules: string = environment.baseUrlRolModules;
  private readonly _baseUrlRolRead: string = environment.baseUrlRolRead;
  private readonly _baseUrlRolUpdate: string = environment.baseUrlRolUpdate;
  private readonly _baseUrlRolUpdatePermission: string = environment.baseUrlRolUpdatePermission;

  private http = inject(HttpClient);

  override create(rolPermission: RolPermissionInterface): Observable<RespInterface<RolPermissionInterface>> {
    const { id: _id, ...newRolPermission } = rolPermission;
    const url: string = this._baseUrlRolCreate;
    return this.http.post<RespInterface<RolPermissionInterface>>(`${url}`, newRolPermission);
  }

  override list(query: string): Observable<RespListInterface<RolPermissionInterface>> {
    const url: string = this._baseUrlRolList;
    return this.http.get<RespListInterface<RolPermissionInterface>>(`${url}/${query}`);
  }

  override listModules(): Observable<RespListInterface<ModulesInterface>> {
    const url: string = this._baseUrlRolModules;
    return this.http.get<RespListInterface<ModulesInterface>>(`${url}`);
  }

  override update(rolPermission: RolPermissionInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlRolUpdate;
    const data = {
      name: rolPermission.name,
      description: rolPermission.description
    }
    return this.http.patch<RespInterface<string>>(`${url}/${rolPermission.id}`, data);
  }

  override updateAction(action: ActionInterface): Observable<RespInterface<RolPermissionInterface>> {
    const url: string = this._baseUrlRolUpdatePermission;
    const data = {
      active: action.active
    }
    return this.http.patch<RespInterface<RolPermissionInterface>>(`${url}/${action.idPermission}`, data);
  }

  override read(id: string): Observable<RespInterface<RolPermissionInterface>> {
    const url: string = this._baseUrlRolRead;
    return this.http.get<RespInterface<RolPermissionInterface>>(`${url}/${id}`);
  }

  override delete(_id: string): Observable<RespInterface<RolPermissionInterface>> {
    const url: string = this._baseUrlRolDelete;
    return this.http.delete<RespInterface<RolPermissionInterface>>(`${url}/${_id}`);
  }
}
