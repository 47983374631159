import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface } from '@common/ports/interfaces';
import { ValidatorFormGroupHelper } from '@common/domain/helpers';

import { FormPaymentAbstraction } from '@form-payment/ports/abstractions';
import { FormPaymentInterface } from '@form-payment/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class FormPaymentRepository extends FormPaymentAbstraction {
  private readonly _baseUrlFormPaymentCreate: string = environment.baseUrlFormPaymentCreate;
  private readonly _baseUrlFormPaymentDelete: string = environment.baseUrlFormPaymentDelete;
  private readonly _baseUrlFormPaymentList: string = environment.baseUrlFormPaymentList;
  private readonly _baseUrlFormPaymentRead: string = environment.baseUrlFormPaymentRead;
  private readonly _baseUrlFormPaymentUpdate: string = environment.baseUrlFormPaymentUpdate;

  private http = inject(HttpClient);
  private formData = inject(ValidatorFormGroupHelper);

  override list(query: string): Observable<RespListInterface<FormPaymentInterface>> {
    const url: string = this._baseUrlFormPaymentList;
    return this.http.get<RespListInterface<FormPaymentInterface>>(`${url}/${query}`);
  }

  override create(formPayment: FormPaymentInterface): Observable<RespInterface<FormPaymentInterface>> {
    const { id, ...newFormPayment } = formPayment;
    const url: string = this._baseUrlFormPaymentCreate;

    const dataFormPayment = this.formData.formData(newFormPayment);

    return this.http.post<RespInterface<FormPaymentInterface>>(`${url}`, dataFormPayment);
  }

  override update(formPayment: FormPaymentInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlFormPaymentUpdate;
    const { id, ...newFormPayment } = formPayment;

    const dataFormPayment = this.formData.formData(newFormPayment);

    return this.http.patch<RespInterface<string>>(`${url}/${id}`, dataFormPayment);
  }

  override read(id: string): Observable<RespInterface<FormPaymentInterface>> {
    const url: string = this._baseUrlFormPaymentRead;
    return this.http.get<RespInterface<FormPaymentInterface>>(`${url}/${id}`);
  }

  override delete(id: string): Observable<RespInterface<FormPaymentInterface>> {
    const url: string = this._baseUrlFormPaymentDelete;
    return this.http.delete<RespInterface<FormPaymentInterface>>(`${url}/${id}`);
  }
}
