import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { RespInterface, RespListInterface, UserInterface } from '@common/ports/interfaces';

import { UserAbstraction } from '@users/ports/abstractions';
import { ProfileInterface } from '@users/ports/interfaces';


@Injectable({
  providedIn: 'root'
})
export class UserRepository extends UserAbstraction {

  private http = inject(HttpClient);

  private readonly _baseUrlUserCreate: string = environment.baseUrlUserCreate;
  private readonly _baseUrlUserDelete: string = environment.baseUrlUserDelete;
  private readonly _baseUrlUserLit: string = environment.baseUrlUserList;
  private readonly _baseUrlUserProfile: string = environment.baseUrlUserProfile;
  private readonly _baseUrlUserRead: string = environment.baseUrlUserRead;
  private readonly _baseUrlUserUdate: string = environment.baseUrlUserUdate;

  override list(query: string): Observable<RespListInterface<UserInterface>> {
    const url = this._baseUrlUserLit;
    return this.http.get<RespListInterface<UserInterface>>(`${url}/${query}`);
  }

  override create(user: UserInterface): Observable<RespInterface<UserInterface>> {
    const { id, ...newUser } = user;
    const url = this._baseUrlUserCreate;

    return this.http.post<RespInterface<UserInterface>>(`${url}`, newUser);
  }

  override read(id: string): Observable<RespInterface<UserInterface>> {
    const url: string = this._baseUrlUserRead;
    return this.http.get<RespInterface<UserInterface>>(`${url}/${id}`);
  }

  override update(user: UserInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlUserUdate;

    const { id, ...newUser } = user;

    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newUser);
  }

  override profileGet(): Observable<RespInterface<UserInterface>> {
    const url: string = this._baseUrlUserProfile;
    return this.http.get<RespInterface<UserInterface>>(`${url}`);
  }

  override profileUpdate(user: ProfileInterface): Observable<RespInterface<ProfileInterface>> {
    const url: string = this._baseUrlUserProfile;

    const newUser = this.removeNullProperties(user);

    return this.http.patch<RespInterface<ProfileInterface>>(`${url}`, newUser);
  }

  override delete(id: string): Observable<RespInterface<UserInterface>> {
    const url: string = this._baseUrlUserDelete;
    return this.http.delete<RespInterface<UserInterface>>(`${url}/${id}`);
  }

  private removeNullProperties<T extends Record<string, any>>(obj: T): T {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== null)
    ) as T;
  }
}
