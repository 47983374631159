import { Routes } from '@angular/router';
import { isAuthenticatedGuard, isLoginGuard, isPermissionsGuard } from '@common/domain/guards';

export const routes: Routes = [
  {
    path: 'auth',
    canActivate: [isLoginGuard],
    loadComponent: () => import('./auth/infraestructure/auth.component').then(c => c.AuthComponent),
    children: [
      {
        path: 'loginclient',
        title: 'Login cliente',
        loadComponent: () => import('./auth/infraestructure/pages/client-login/login-user.component').then(c => c.LoginUserComponent),
      },
      {
        path: 'loginadministrative',
        title: 'Login administrativos',
        loadComponent: () => import('./auth/infraestructure/pages/login-administrative/login-administrative.component').then(c => c.LoginAdministrativeComponent),
      },
      {
        path: 'recoverpassword',
        title: 'Recover password',
        loadComponent: () => import('./auth/infraestructure/pages/recover-password/recover-password.component').then(c => c.RecoverPasswordComponent),
      },
      {
        path: 'recoverpasswordclient',
        title: 'Recordar contraseña cliente',
        loadComponent: () => import('./auth/infraestructure/pages/client-recover-password/recover-password-user.component').then(c => c.RecoverPasswordUserComponent),
      },
      {
        path: 'new-password/:token',
        title: 'New password',
        loadComponent: () => import('./auth/infraestructure/pages/new-password/new-password.component').then(c => c.NewPasswordComponent),
      },
      {
        path: 'new-password-client/:token',
        title: 'New password cliente',
        loadComponent: () => import('./auth/infraestructure/pages/client-new-password/client-new-password.component').then(c => c.ClientNewPasswordComponent),
      },
      {
        path: '**',
        redirectTo: 'loginclient',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'registerclient',
    title: 'Registro del cliente',
    canActivate: [isLoginGuard],
    loadComponent: () => import('./auth/infraestructure/pages/client-register/register-client.component').then(c => c.RegisterClientComponent),
  },
  {
    path: 'main',
    canActivate: [isAuthenticatedGuard],
    loadComponent: () => import('./home/infraestructure/home.component').then(c => c.HomeComponent),
    children: [
      {
        path: 'dashboard',
        title: 'Inicio',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./home/infraestructure/pages/dashboard/dashboard.component').then(c => c.DashboardComponent)
      },
      {
        path: 'profile',
        title: 'Perfil',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./users/infraestructure/pages/profile/profile.component').then(c => c.ProfileComponent)
      },
      {
        path: 'profile/client',
        title: 'Perfil del cliente',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./clients/infraestructure/pages/profile/profile.component').then(c => c.ProfileClientComponent)
      },
      {
        path: 'rolesandpermissions',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./roles-permissions/infraestructure/roles-permissions.component').then(c => c.RolesPermissionsComponent),
        children: [
          {
            path: 'list',
            title: 'Listas de roles y permisos',
            loadComponent: () => import('./roles-permissions/infraestructure/pages/list/list.component').then(c => c.ListRolePermissionComponent)
          },
          {
            path: 'create',
            title: 'Crear roles y permisos',
            loadComponent: () => import('./roles-permissions/infraestructure/pages/create/create.component').then(c => c.CreateRolePermissionComponent)
          },
          {
            path: 'update/:id',
            title: 'Editar rol y permiso',
            loadComponent: () => import('./roles-permissions/infraestructure/pages/update/update.component').then(c => c.UpdateRolePermissionComponent)
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'user',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./users/infraestructure/users.component').then(c => c.UsersComponent),
        children: [
          {
            path: 'list',
            title: 'Listas de usuarios',
            loadComponent: () => import('./users/infraestructure/pages/list/list.component').then(c => c.ListUserComponent)
          },
          {
            path: 'create',
            title: 'Crear usuario',
            loadComponent: () => import('./users/infraestructure/pages/create/create.component').then(c => c.CreateUserComponent)
          },
          {
            path: 'update/:id',
            title: 'Editar usuario',
            loadComponent: () => import('./users/infraestructure/pages/update/update.component').then(c => c.UpdateUserComponent)
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'setting',
        loadComponent: () => import('./config/infraestructure/config/config.component').then(c => c.ConfigComponent),
        children: [
          {
            path: 'country',
            canActivate: [isPermissionsGuard],
            loadComponent: () => import('./countries/infraestructure/countries.component').then(c => c.CountriesComponent),
            children: [
              {
                path: 'list',
                title: 'Listas de países',
                loadComponent: () => import('./countries/infraestructure/pages/list/list.component').then(c => c.ListCountryComponent),
              },
              {
                path: 'create',
                title: 'Crear país',
                loadComponent: () => import('./countries/infraestructure/pages/create/create.component').then(c => c.CreateCountryComponent),
              },
              {
                path: 'update/:id',
                title: 'Editar país',
                loadComponent: () => import('./countries/infraestructure/pages/update/update.component').then(c => c.UpdateCountryComponent),
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              },
            ]
          },
          {
            path: 'state',
            canActivate: [isPermissionsGuard],
            loadComponent: () => import('./state/infraestructure/state.component').then(c => c.StateComponent),
            children: [
              {
                path: 'list',
                title: 'Listas de estados o departamentos',
                loadComponent: () => import('./state/infraestructure/pages/list/list.component').then(c => c.ListStateComponent),
              },
              {
                path: 'create',
                title: 'Crear estado o departamentos',
                loadComponent: () => import('./state/infraestructure/pages/create/create.component').then(c => c.CreateStateComponent),
              },
              {
                path: 'update/:id',
                title: 'Editar estado o departamentos',
                loadComponent: () => import('./state/infraestructure/pages/update/update.component').then(c => c.UpdateStateComponent),
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              },
            ]
          },
          {
            path: 'city',
            canActivate: [isPermissionsGuard],
            loadComponent: () => import('./cities/infraestructure/cities.component').then(c => c.CitiesComponent),
            children: [
              {
                path: 'list',
                title: 'Listas de ciudades o municipios',
                loadComponent: () => import('./cities/infraestructure/pages/list/list.component').then(c => c.ListCityComponent),
              },
              {
                path: 'create',
                title: 'Crear ciudade o municipio',
                loadComponent: () => import('./cities/infraestructure/pages/create/create.component').then(c => c.CreateCityComponent),
              },
              {
                path: 'update/:id',
                title: 'Editar ciudade o municipio',
                loadComponent: () => import('./cities/infraestructure/pages/update/update.component').then(c => c.UpdateCityComponent),
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              },
            ]
          },
          {
            path: 'formspayment',
            canActivate: [isPermissionsGuard],
            loadComponent: () => import('./form-payment/infraestructure/forms-payment.component').then(c => c.FormPaymentComponent),
            children: [
              {
                path: 'list',
                title: 'Listas de formas de pagos',
                loadComponent: () => import('./form-payment/infraestructure/pages/list/list.component').then(c => c.ListFormPaymentComponent),
              },
              {
                path: 'create',
                title: 'Crear forma de pago',
                loadComponent: () => import('./form-payment/infraestructure/pages/create/create.component').then(c => c.CreateFormPaymentComponent),
              },
              {
                path: 'update/:id',
                title: 'Editar forma de pago',
                loadComponent: () => import('./form-payment/infraestructure/pages/update/update.component').then(c => c.UpdateFormPaymentComponent),
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              },
            ]
          },
          {
            path: 'deliverytypes',
            canActivate: [isPermissionsGuard],
            loadComponent: () => import('./delivery-type/infraestructure/delivery-type.component').then(c => c.DeliveryTypeComponent),
            children: [
              {
                path: 'list',
                title: 'Listas de tipos de entregas',
                loadComponent: () => import('./delivery-type/infraestructure/pages/list/list.component').then(c => c.ListDeliveryTypeComponent),
              },
              {
                path: 'create',
                title: 'Crear tipo de entrega',
                loadComponent: () => import('./delivery-type/infraestructure/pages/create/create.component').then(c => c.CreateDeliveryTypeComponent),
              },
              {
                path: 'update/:id',
                title: 'Editar tipo de entrega',
                loadComponent: () => import('./delivery-type/infraestructure/pages/update/update.component').then(c => c.UpdateDeliveryTypeComponent),
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              },
            ]
          },
        ]
      },
      {
        path: 'institutions',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./institution/infraestructure/institution.component').then(c => c.InstitutionComponent),
        children: [
          {
            path: 'list',
            title: 'Listas de eventos',
            loadComponent: () => import('./institution/infraestructure/pages/list/list.component').then(c => c.ListInstitutionComponent),
          },
          {
            path: 'create',
            title: 'Crear evento',
            loadComponent: () => import('./institution/infraestructure/pages/create/create.component').then(c => c.CreateInstitutionComponent),
          },
          {
            path: 'update/:id',
            title: 'Editar evento',
            loadComponent: () => import('./institution/infraestructure/pages/update/update.component').then(c => c.UpdateInstitutionComponent),
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'productsandservices',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./products-services/infraestructure/products-services.component').then(c => c.ProductsServicesComponent),
        children: [
          {
            path: 'list',
            title: 'Listas de productos y servicios',
            loadComponent: () => import('./products-services/infraestructure/pages/list/list.component').then(c => c.ListProductServiceComponent),
          },
          {
            path: 'create',
            title: 'Crear producto y servicio',
            loadComponent: () => import('./products-services/infraestructure/pages/create/create.component').then(c => c.CreateProductServiceComponent),
          },
          {
            path: 'update/:id',
            title: 'Editar producto y servicio',
            loadComponent: () => import('./products-services/infraestructure/pages/update/update.component').then(c => c.UpdateProductServiceComponent),
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'clients',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./clients/infraestructure/clients.component').then(c => c.ClientsComponent),
        children: [
          {
            path: 'list',
            title: 'Listas de productos y servicios',
            loadComponent: () => import('./clients/infraestructure/pages/list/list.component').then(c => c.ListClientComponent),
          },
          {
            path: 'create',
            title: 'Crear producto y servicio',
            loadComponent: () => import('./clients/infraestructure/pages/create/create.component').then(c => c.CreateClientComponent),
          },
          {
            path: 'bulkload',
            title: 'Carga masiva de clientes',
            loadComponent: () => import('./clients/infraestructure/pages/bulk-load/bulk-load.component').then(c => c.BulkLoadComponent)
          },
          {
            path: 'update/:id',
            title: 'Editar producto y servicio',
            loadComponent: () => import('./clients/infraestructure/pages/update/update.component').then(c => c.UpdateClientComponent),
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'sales',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./sales/infraestructure/sales.component').then(c => c.SalesComponent),
        children: [
          {
            path: 'list',
            title: 'Listas de ventas',
            loadComponent: () => import('./sales/infraestructure/pages/list/list.component').then(c => c.ListSaleComponent),
          },
          {
            path: 'create',
            title: 'Crear venta',
            loadComponent: () => import('./sales/infraestructure/pages/create/create.component').then(c => c.CreateSaleComponent),
          },
          {
            path: 'update/:id',
            title: 'Editar venta',
            loadComponent: () => import('./sales/infraestructure/pages/update/update.component').then(c => c.UpdateSaleComponent),
          },
          {
            path: 'invoice/:id',
            title: 'Factura de venta',
            loadComponent: () => import('./sales/infraestructure/pages/invoice/invoice.component').then(c => c.SaleInvoiceComponent),
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'orders',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./orders/infraestructure/orders.component').then(c => c.OrdersComponent),
        children: [
          {
            path: 'list',
            title: 'Listas de ventas',
            loadComponent: () => import('./orders/infraestructure/pages/list/list.component').then(c => c.OrdersListComponent),
          },
          {
            path: 'create',
            title: 'Crear venta',
            loadComponent: () => import('./orders/infraestructure/pages/create/create.component').then(c => c.OrdersCreateComponent),
          },
          {
            path: 'update/:id',
            title: 'Editar venta',
            loadComponent: () => import('./orders/infraestructure/pages/update/update.component').then(c => c.OrdersUpdateComponent),
          },
          {
            path: 'invoice/:id',
            title: 'Factura de venta',
            loadComponent: () => import('./orders/infraestructure/pages/invoice/invoice.component').then(c => c.OrdersInvoiceComponent),
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: 'recognition',
        canActivate: [isPermissionsGuard],
        loadComponent: () => import('./recognition/infraestructure/recognition.component').then(c => c.RecognitionComponent),
        children: [
          {
            path: 'home',
            title: 'Reconocimiento facial',
            loadComponent: () => import('./recognition/infraestructure/pages/home/home.component').then(c => c.HomeComponent),
          },
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full'
  }
];
