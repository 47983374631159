import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, UserInterface } from '@common/ports/interfaces';

import { AuthAbstraction } from '@auth/ports/abstractions';
import { AuthInterface } from '@auth/ports/interfaces';
import { ClientInterface } from '@clients/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthRepository extends AuthAbstraction {
  private readonly _baseUrlLogin: string = environment.baseUrlLogin;
  private readonly _baseUrlRecoverPassword: string = environment.baseUrlRecoverPassword;
  private readonly _baseUrlNewPassword: string = environment.baseUrlNewPassword;

  private readonly _baseUrlClientRegister: string = environment.baseUrlClientRegister;
  private readonly _baseUrlLoginClien: string = environment.baseUrlClientLogin;
  private readonly _baseUrlRecoverPasswordClient: string = environment.baseUrlClientRecoverPassword;
  private readonly _baseUrlNewPasswordClient: string = environment.baseUrlClientNewPassword;

  private http = inject(HttpClient);

  override updatePassword(password: string, token: string): Observable<RespInterface<UserInterface>> {
    const url: string = this._baseUrlNewPassword;

    const data = {
      token: token,
      password: password
    }

    return this.http.post<RespInterface<UserInterface>>(url, data);
  }
  override recoverPassword(email: string): Observable<RespInterface<UserInterface>> {
    const url: string = this._baseUrlRecoverPassword;

    const data = {
      email: email,
    };
    return this.http.post<RespInterface<UserInterface>>(url, data);
  }

  override login(login: AuthInterface): Observable<RespInterface<UserInterface>> {
    const url: string = this._baseUrlLogin;

    const data = {
      email: login.email,
      password: login.password,
    };
    return this.http.post<RespInterface<UserInterface>>(url, data);
  }

  override loginClient(login: AuthInterface): Observable<RespInterface<ClientInterface>> {
    const url: string = this._baseUrlLoginClien;

    const data = {
      email: login.email,
      password: login.password,
    };
    return this.http.post<RespInterface<ClientInterface>>(url, data);
  }

  override createClient(login: ClientInterface): Observable<RespInterface<string>> {
    const { id: _id, institutions, typeIdentification, city, ...newClient } = login;
    const url: string = this._baseUrlClientRegister;
    return this.http.post<RespInterface<string>>(`${url}`, newClient);
  }

  override recoverPasswordClient(email: string): Observable<RespInterface<string>> {
    const url: string = this._baseUrlRecoverPasswordClient;

    const data = {
      email: email,
    };
    return this.http.post<RespInterface<string>>(url, data);
  }

  override updatePasswordClient(password: string, token: string): Observable<RespInterface<string>> {
    const url: string = this._baseUrlNewPasswordClient;

    const data = {
      token: token,
      password: password
    }

    return this.http.post<RespInterface<string>>(url, data);
  }
}
