import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { LoadingService } from '@common/application/uses-cases';
import { PrimeNGModule } from '@common/domain/prime-ng/prime-ng.module';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'common-loading',
  standalone: true,
  imports: [
    PrimeNGModule
  ],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.css'
})
export class CommonLoadingComponent implements AfterViewInit, OnDestroy {
  private isLoading$: Subject<boolean>;
  #loading: Subscription = new Subscription();
  public isLoading: boolean = false;

  constructor(private loadingInteractor: LoadingService) {
    this.isLoading$ = this.loadingInteractor.getLoading();
  }


  ngAfterViewInit() {
    this.#loading = this.isLoading$.subscribe(isLoading => this.isLoading = isLoading);
  }

  ngOnDestroy(): void {
    this.#loading.unsubscribe();
  }
}


