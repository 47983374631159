import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ToastAbstraction } from '@common/ports/abstractions';
import { ModulesInterface } from '@common/ports/interfaces';
import { HomeService } from '@home/application/uses-cases/home.service';

export const isPermissionsGuard: CanActivateFn = async (route, state) => {
  const homeService = inject(HomeService);
  const msgAbstraction = inject(ToastAbstraction);
  const router = inject(Router);

  const type = localStorage.getItem('type');
  let modules: ModulesInterface[] = [];

  if (type && (type === 'User' || type === 'Administrator')) {
    await homeService.getPermission();
    modules = homeService.listPermission();
  } else {
    modules = homeService.listPermissionClient();
  }

  const hasAccess = modules.some(module => module.routerLink === state.url);
  const hasAccessParen = modules.some(module => module.code === route.routeConfig?.path);

  if (hasAccess || hasAccessParen) return true;

  if (modules.length > 0) {
    router.navigate([modules[0].routerLink]);
    return false;
  }

  msgAbstraction.notify('error', 'Permisos del sistema', 'El usuario no tiene permisos sobre esta acción', true, 3000);
  localStorage.clear();
  sessionStorage.clear();
  router.navigate(['/']);
  return false;
};
