import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ListAbstraction } from '@common/ports/abstractions/list/list.abstraction';
import { ListInterface, RespListInterface, UserInterface } from '@common/ports/interfaces';

import { environment } from '@environments/environment';
import { CityInterface } from '@city/ports/interfaces';
import { InstitutionInterface } from '@institution/ports/interfaces';
import { ClientInterface } from '@clients/ports/interfaces';
import { RolPermissionInterface } from '@roles-permissions/ports/interfaces';
import { CountryInterface } from '@countries/ports/interfaces';
import { DeliveryTypeInterface } from '@delivery-type/ports/interfaces';
import { FormPaymentInterface } from '@form-payment/ports/interfaces';
import { ProductServiceInterface } from '@products-services/ports/interfaces';
import { SaleInterface } from '@sales/ports/interfaces';
import { StateInterface } from '@state/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ListRepository extends ListAbstraction {
  private http = inject(HttpClient);

  private readonly _baseUrlListCity = environment.baseUrlListCity;
  private readonly _baseUrlListClient = environment.baseUrlListClient;
  private readonly _baseUrlListCountry = environment.baseUrlListCountry;
  private readonly _baseUrlListDeliveryType = environment.baseUrlListDeliveryType;
  private readonly _baseUrlListInstitution = environment.baseUrlListInstitution;
  private readonly _baseUrlListPaid = environment.baseUrlListPaid;
  private readonly _baseUrlListProduct = environment.baseUrlListProduct;
  private readonly _baseUrlListRol = environment.baseUrlListRol;
  private readonly _baseUrlListSales = environment.baseUrlListSales;
  private readonly _baseUrlListState = environment.baseUrlListState;
  private readonly _baseUrlListTypeDocument = environment.baseUrlListTypeDocument;
  private readonly _baseUrlListUser = environment.baseUrlListUser;

  override listTypeDocument(): Observable<RespListInterface<ListInterface>> {
    const url = this._baseUrlListTypeDocument;
    return this.http.get<RespListInterface<ListInterface>>(`${url}`);
  }

  override listRol(query: string): Observable<RespListInterface<RolPermissionInterface>> {
    const url = this._baseUrlListRol;
    return this.http.get<RespListInterface<RolPermissionInterface>>(`${url}/${query}`);
  }

  override listCity(query: string): Observable<RespListInterface<CityInterface>> {
    const url = this._baseUrlListCity;
    return this.http.get<RespListInterface<CityInterface>>(`${url}/${query}`);
  }

  override listInstitution(query: string): Observable<RespListInterface<InstitutionInterface>> {
    const url = this._baseUrlListInstitution;
    return this.http.get<RespListInterface<InstitutionInterface>>(`${url}/${query}`);
  }

  override listClient(query: string): Observable<RespListInterface<ClientInterface>> {
    const url = this._baseUrlListClient;
    return this.http.get<RespListInterface<ClientInterface>>(`${url}/${query}`);
  }

  override listUser(query: string): Observable<RespListInterface<UserInterface>> {
    const url = this._baseUrlListUser;
    return this.http.get<RespListInterface<UserInterface>>(`${url}/${query}`);
  }

  override listCountry(query: string): Observable<RespListInterface<CountryInterface>> {
    const url = this._baseUrlListCountry;
    return this.http.get<RespListInterface<CountryInterface>>(`${url}/${query}`);
  }

  override listDeliveryType(query: string): Observable<RespListInterface<DeliveryTypeInterface>> {
    const url = this._baseUrlListDeliveryType;
    return this.http.get<RespListInterface<DeliveryTypeInterface>>(`${url}/${query}`);
  }

  override listPaid(query: string): Observable<RespListInterface<FormPaymentInterface>> {
    const url = this._baseUrlListPaid;
    return this.http.get<RespListInterface<FormPaymentInterface>>(`${url}/${query}`);
  }

  override listProduct(query: string): Observable<RespListInterface<ProductServiceInterface>> {
    const url = this._baseUrlListProduct;
    return this.http.get<RespListInterface<ProductServiceInterface>>(`${url}/${query}`);
  }

  override listSale(query: string): Observable<RespListInterface<SaleInterface>> {
    const url = this._baseUrlListSales;
    return this.http.get<RespListInterface<SaleInterface>>(`${url}/${query}`);
  }

  override listState(query: string): Observable<RespListInterface<StateInterface>> {
    const url = this._baseUrlListState;
    return this.http.get<RespListInterface<StateInterface>>(`${url}/${query}`);
  }
}
