import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface } from '@common/ports/interfaces';

import { InstitutionAbstraction } from '@institution/ports/abstractions';
import { InstitutionInterface } from '@institution/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class InstitutionRepository extends InstitutionAbstraction {

  private readonly _baseUrlInstitutionCreate: string = environment.baseUrlInstitutionCreate;
  private readonly _baseUrlInstitutionDelete: string = environment.baseUrlInstitutionDelete;
  private readonly _baseUrlInstitutionList: string = environment.baseUrlInstitutionList;
  private readonly _baseUrlInstitutionRead: string = environment.baseUrlInstitutionRead;
  private readonly _baseUrlInstitutionUpdate: string = environment.baseUrlInstitutionUpdate;

  private http = inject(HttpClient);

  override list(query: string): Observable<RespListInterface<InstitutionInterface>> {
    const url: string = this._baseUrlInstitutionList;
    return this.http.get<RespListInterface<InstitutionInterface>>(`${url}/${query}`);
  }

  override create(institution: InstitutionInterface): Observable<RespInterface<InstitutionInterface>> {
    const { id, city, ...newInstitution } = institution;
    const url: string = this._baseUrlInstitutionCreate;
    return this.http.post<RespInterface<InstitutionInterface>>(`${url}`, newInstitution);
  }

  override update(institution: InstitutionInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlInstitutionUpdate;
    const { id, city, ...newInstitution } = institution;
    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newInstitution);
  }

  override read(id: string): Observable<RespInterface<InstitutionInterface>> {
    const url: string = this._baseUrlInstitutionRead;
    return this.http.get<RespInterface<InstitutionInterface>>(`${url}/${id}`);
  }

  override delete(id: string): Observable<RespInterface<InstitutionInterface>> {
    const url: string = this._baseUrlInstitutionDelete;
    return this.http.delete<RespInterface<InstitutionInterface>>(`${url}/${id}`);
  }
}
