import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface } from '@common/ports/interfaces';

import { StateAbstraction } from '@state/ports/abstractions';
import { StateInterface } from '@state/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class StateRepository extends StateAbstraction {

  private readonly _baseUrlStateCreate: string = environment.baseUrlStateCreate;
  private readonly _baseUrlStateDelete: string = environment.baseUrlStateDelete;
  private readonly _baseUrlStateList: string = environment.baseUrlStateList;
  private readonly _baseUrlStateRead: string = environment.baseUrlStateRead;
  private readonly _baseUrlStateUpdate: string = environment.baseUrlStateUpdate;

  private http = inject(HttpClient);

  override list(query: string): Observable<RespListInterface<StateInterface>> {
    const url: string = this._baseUrlStateList;
    return this.http.get<RespListInterface<StateInterface>>(`${url}/${query}`);
  }

  override create(state: StateInterface): Observable<RespInterface<StateInterface>> {
    const { id, country, ...newState } = state;
    newState.countryId = country.id;
    const url: string = this._baseUrlStateCreate;
    return this.http.post<RespInterface<StateInterface>>(`${url}`, newState);
  }

  override update(state: StateInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlStateUpdate;
    const { id, country, ...newState } = state;
    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newState);
  }

  override read(id: string): Observable<RespInterface<StateInterface>> {
    const url: string = this._baseUrlStateRead;
    return this.http.get<RespInterface<StateInterface>>(`${url}/${id}`);
  }

  override delete(id: string): Observable<RespInterface<StateInterface>> {
    const url: string = this._baseUrlStateDelete;
    return this.http.delete<RespInterface<StateInterface>>(`${url}/${id}`);
  }
}
