import { Component, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { PrimeNGModule } from '@common/domain/prime-ng/prime-ng.module';
import { Severities } from '@common/ports/interfaces';
import { ToastService } from '@common/application/uses-cases';

@Component({
  selector: 'common-toast',
  standalone: true,
  imports: [
    PrimeNGModule
  ],
  providers: [MessageService],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css'
})
export class CommonToastComponent implements OnDestroy {

  public timeLife: number = 4000;

  subscription: Subscription;
  constructor(
    private messagesInteractor: ToastService,
    private messageService: MessageService
  ) {
    this.subscription = this.messagesInteractor.notificationChange
      .subscribe((notification: { severity: Severities; summary: string; detail: string; timeLife: number; }) => {
        this.timeLife = notification.timeLife;
        this.messageService.add({
          key: 'br',
          severity: notification.severity,
          summary: notification.summary,
          detail: notification.detail
        })
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
