import { Injectable } from '@angular/core';
import { ToastAbstraction } from '@common/ports/abstractions';
import { Severities, ToastInterface } from '@common/ports/interfaces';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastRepository extends ToastAbstraction {

  override notificationChange: Subject<ToastInterface> = new Subject<ToastInterface>();

  override notify(severity: Severities, summary: string, detail: string, viewToast: boolean, timeLife?: number): void {
    const effectiveTimeLife = timeLife ?? 2000;
    if (viewToast) {
      this.notificationChange.next({ summary: summary, detail: detail, severity: severity, timeLife: effectiveTimeLife });
    }
  }
}
