import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface, ValidatorFormGroupInterface } from '@common/ports/interfaces';
import { RecognitionAbstraction } from '@recognition/ports/abstrations';
import { ValidatorFormGroupHelper } from '@common/domain/helpers';
import { AddImgInterface, RecognitionFilterInterface } from '@recognition/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class RecognitionRepository extends RecognitionAbstraction {
  private validatorForm: ValidatorFormGroupInterface = new ValidatorFormGroupHelper()
  private http = inject(HttpClient);

  private readonly _baseUrlRecognitionCreate: string = environment.baseUrlRecognitionCreate;
  private readonly _baseUrlRecognitionList: string = environment.baseUrlRecognitionList;
  private readonly _baseUrlRecognitionAddImg: string = environment.baseUrlRecognitionAddImg;

  override list(query: RecognitionFilterInterface): Observable<RespListInterface<string>> {
    const url: string = this._baseUrlRecognitionList;
    return this.http.post<RespListInterface<string>>(`${url}`, query);
  }

  override create(recognition: File): Observable<RespInterface<{ name: string }>> {
    const data = {
      file: recognition
    };
    const newRecognition = this.validatorForm.formData(data);
    const url: string = this._baseUrlRecognitionCreate;
    return this.http.post<RespInterface<{ name: string }>>(`${url}`, newRecognition);
  }

  override addImg(addImg: AddImgInterface): Observable<RespListInterface<string>> {
    const url: string = this._baseUrlRecognitionAddImg;
    return this.http.patch<RespListInterface<string>>(`${url}`, addImg);
  }
}
