import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';

import { CommonToastComponent } from '@common/infraestructure/components/toast/toast.component';
import { CommonLoadingComponent } from '@common/infraestructure/components/loading/loading.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RouterOutlet,

    CommonToastComponent,
    CommonLoadingComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy {
  private primeNGConfig = inject(PrimeNGConfig);
  private http = inject(HttpClient);
  private _getJsonData: Subscription = new Subscription();

  ngOnInit(): void {
    this._getJsonData = this.getJsonData().subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });
  }

  getJsonData(): Observable<any> {
    return this.http.get<any>('assets/locale/es.json');
  }

  ngOnDestroy(): void {
    this._getJsonData.unsubscribe();
  }
}
