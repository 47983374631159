import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError,
  Observable,
  tap,
  throwError,
} from 'rxjs';

import {
  LoadingAbstraction,
  ToastAbstraction,
} from '@common/ports/abstractions';
import { environment } from '@environments/environment';

export const EvokeInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const router = inject(Router);
  const msgAbstraction = inject(ToastAbstraction);
  const loadingAbstraction = inject(LoadingAbstraction);

  const baseUrl: string = environment.baseUrl;
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  let request = req;

  if (!req.url.includes('.json')) {
    request = req.clone({
      url: `${baseUrl}/${req.url}`,
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return next(request).pipe(
    tap((resp: HttpEvent<any>) => {
      if (resp instanceof HttpResponse) {
        loadingAbstraction.hide();
        if (resp.body) {
          if (resp.status >= 200 && resp.status <= 299) {
            msgAbstraction.notify(
              'success',
              resp.body.summary,
              resp.body.message,
              resp.body.viewToast
            );
          }
          if (resp.status >= 300 && resp.status <= 399) {
            msgAbstraction.notify(
              'warn',
              resp.body.summary,
              resp.body.message,
              resp.body.viewToast
            );
          }
          if (resp.status >= 400) {
            msgAbstraction.notify(
              'error',
              resp.body.summary,
              resp.body.message,
              resp.body.viewToast
            );
          }
        }
      }
    }),
    catchError((request) => {
      loadingAbstraction.hide();
      if (request.error) {
        if (!request.error.data && request.status >= 400)
          msgAbstraction.notify(
            'error',
            `${request.error.error}`,
            `${(request.error.message && request.error.message.length > 1) ? request.error.message.join(', ') : request.error.message}`,
            true
          );
        else if (request.status == 0) {
          msgAbstraction.notify(
            'error',
            `Error en el sistema - Contacte al administrador`,
            `${request.message}`, true
          );
          router.navigate(['']);
          localStorage.clear();
          sessionStorage.clear();
        }
        else
          msgAbstraction.notify(
            'error',
            `${request.error.summary
              ? request.error.summary
              : `Status: ${request.error.statusCode}`
            }`,
            `${request.error.message}`,
            true
          );
        if (request.status === 401) {
          router.navigate(['']);
          localStorage.clear();
          sessionStorage.clear();
        }
      }

      return throwError(() => request);
    })
  );
};
