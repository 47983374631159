import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface } from '@common/ports/interfaces';

import { SaleAbstraction } from '@sales/ports/abstractions';
import {
  ListPaymentInterface,
  SaleCreateInterface, SaleDeliveryStateInterface, SaleInterface, SalePaidApprovedInterface, SalePaidInterface,
  SaleProductCancelInterface, SaleProductNotifyInterface, SaleProductoInterface, SaleProductUpdateInterface, SaleUpdateInterface,
  SellingProductInterface
} from '@sales/ports/interfaces';
import { ValidatorFormGroupHelper } from '@common/domain/helpers';

@Injectable({
  providedIn: 'root'
})
export class SaleRepository extends SaleAbstraction {
  private readonly _baseUrlSaleCreate: string = environment.baseUrlSaleCreate;
  private readonly _baseUrlSaleDelete: string = environment.baseUrlSaleDelete;
  private readonly _baseUrlSaleEmail: string = environment.baseUrlSaleEmail;
  private readonly _baseUrlSaleList: string = environment.baseUrlSaleList;
  private readonly _baseUrlSaleListPayment: string = environment.baseUrlSaleListPayment;
  private readonly _baseUrlSaleRead: string = environment.baseUrlSaleRead;
  private readonly _baseUrlSaleUpdate: string = environment.baseUrlSaleUpdate;

  private readonly _baseUrlSaleDeliveryState: string = environment.baseUrlSaleDeliveryState;
  private readonly _baseUrlSaleProductCancel: string = environment.baseUrlSaleProductCancel;
  private readonly _baseUrlSaleProductCreate: string = environment.baseUrlSaleProductCreate;
  private readonly _baseUrlSaleProductNotify: string = environment.baseUrlSaleProductNotify;
  private readonly _baseUrlSaleProductUpdate: string = environment.baseUrlSaleProductUpdate;
  private readonly _baseUrlSaleSellingProduct: string = environment.baseUrlSaleSellingProduct;

  private readonly _baseUrlSalePaidApproved: string = environment.baseUrlSalePaidApproved;
  private readonly _baseUrlSalePaidCreate: string = environment.baseUrlSalePaidCreate;
  private readonly _baseUrlSalePaidUpdate: string = environment.baseUrlSalePaidUpdate;

  private http = inject(HttpClient);
  private formData = inject(ValidatorFormGroupHelper);

  override list(query: string): Observable<RespListInterface<SaleInterface>> {
    const url: string = this._baseUrlSaleList;
    return this.http.get<RespListInterface<SaleInterface>>(`${url}/${query}`);
  }

  override listPayment(query: string): Observable<RespListInterface<ListPaymentInterface>> {
    const url: string = this._baseUrlSaleListPayment;
    return this.http.get<RespListInterface<ListPaymentInterface>>(`${url}/${query}`);
  }

  override create(sale: SaleCreateInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlSaleCreate;
    return this.http.post<RespInterface<string>>(`${url}`, sale);
  }

  override update(sale: SaleUpdateInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlSaleUpdate;
    const { id, ...newSale } = sale;

    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newSale);
  }

  override read(id: string): Observable<RespInterface<SaleInterface>> {
    const url: string = this._baseUrlSaleRead;
    return this.http.get<RespInterface<SaleInterface>>(`${url}/${id}`);
  }

  override delete(sale: SaleInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlSaleDelete;

    const data = {
      userId: sale.userId,
      clientId: sale.clientId,
    }

    return this.http.delete<RespInterface<string>>(`${url}/${sale.id}`, { body: data });
  }

  override sendEmail(saleId: string): Observable<RespInterface<string>> {
    const url: string = this._baseUrlSaleEmail;
    return this.http.get<RespInterface<string>>(`${url}/${saleId}`);
  }

  override productCreate(saleProduct: SaleProductoInterface): Observable<RespInterface<string>> {
    let data = {}

    if (saleProduct.cityId) {
      data = saleProduct;
    } else {
      const { cityId, address, ...newSaleProduct } = saleProduct;
      newSaleProduct.freight = 0;
      data = newSaleProduct;
    }

    const url: string = this._baseUrlSaleProductCreate;
    return this.http.post<RespInterface<string>>(`${url}`, data);
  }

  override productUpdate(product: SaleProductUpdateInterface): Observable<RespInterface<string>> {
    let data = {}

    if (product.cityId) {
      const { productId, ...newProduct } = product;
      data = newProduct;
    } else {
      const { cityId, address, productId, ...newProduct } = product;
      newProduct.freight = 0;
      data = newProduct;
    }

    const url: string = this._baseUrlSaleProductUpdate;
    return this.http.patch<RespInterface<string>>(`${url}/${product.productId}`, data);
  }

  override productCancel(product: SaleProductCancelInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlSaleProductCancel;
    const { productId, ...newProduct } = product;
    return this.http.delete<RespInterface<string>>(`${url}/${productId}`, { body: newProduct });
  }

  override massShipping(state: SaleDeliveryStateInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlSaleDeliveryState;
    return this.http.patch<RespInterface<string>>(`${url}`, state);
  }

  override notifyProduct(product: SaleProductNotifyInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlSaleProductNotify;
    return this.http.post<RespInterface<string>>(`${url}`, product);
  }

  override sellingProduct(query: string): Observable<RespListInterface<SellingProductInterface>> {
    const url: string = this._baseUrlSaleSellingProduct;
    return this.http.get<RespListInterface<SellingProductInterface>>(`${url}/${query}`);
  }

  override paidCreate(paid: SalePaidInterface): Observable<RespInterface<string>> {
    const { id, ...newPaid } = paid;
    const dataPaid = this.formData.formData(newPaid);

    const url: string = this._baseUrlSalePaidCreate;
    return this.http.post<RespInterface<string>>(`${url}`, dataPaid);
  }

  override paidApproved(paid: SalePaidApprovedInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlSalePaidApproved;
    const { id, ...newPaid } = paid;
    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newPaid);
  }

  override paidUpdate(paid: SalePaidInterface): Observable<RespInterface<string>> {
    const { id, ...newPaid } = paid;
    const dataPaid = this.formData.formData(newPaid);
    const url: string = this._baseUrlSalePaidUpdate;
    return this.http.patch<RespInterface<string>>(`${url}/${id}`, dataPaid);
  }
}
