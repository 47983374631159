import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { RespInterface, RespListInterface } from '@common/ports/interfaces';
import { CityAbstraction } from '@city/ports/abstractions';
import { CityInterface } from '@city/ports/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CityRepository extends CityAbstraction {

  private readonly _baseUrlCityCreate: string = environment.baseUrlCityCreate;
  private readonly _baseUrlCityDelete: string = environment.baseUrlCityDelete;
  private readonly _baseUrlCityList: string = environment.baseUrlCityList;
  private readonly _baseUrlCityRead: string = environment.baseUrlCityRead;
  private readonly _baseUrlCityUpdate: string = environment.baseUrlCityUpdate;

  private http = inject(HttpClient);

  override list(query: string): Observable<RespListInterface<CityInterface>> {
    const url: string = this._baseUrlCityList;
    return this.http.get<RespListInterface<CityInterface>>(`${url}/${query}`);
  }

  override create(city: CityInterface): Observable<RespInterface<CityInterface>> {
    const { id, state, ...newCity } = city;
    newCity.stateId = state.id;
    const url: string = this._baseUrlCityCreate;
    return this.http.post<RespInterface<CityInterface>>(`${url}`, newCity);
  }

  override update(city: CityInterface): Observable<RespInterface<string>> {
    const url: string = this._baseUrlCityUpdate;
    const { id, state, ...newCity } = city;
    return this.http.patch<RespInterface<string>>(`${url}/${id}`, newCity);
  }

  override read(id: string): Observable<RespInterface<CityInterface>> {
    const url: string = this._baseUrlCityRead;
    return this.http.get<RespInterface<CityInterface>>(`${url}/${id}`);
  }

  override delete(id: string): Observable<RespInterface<CityInterface>> {
    const url: string = this._baseUrlCityDelete;
    return this.http.delete<RespInterface<CityInterface>>(`${url}/${id}`);
  }
}
