import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const isLoginGuard: CanActivateFn = (route, state) => {
  // const router = inject(Router);

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  if (token) {
    // router.navigateByUrl('/main/rolesandpermissions');
    return false
  };

  return true;
};
